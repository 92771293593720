$(document).ready(function() {

  if ($("#intro").length) {
    document.getElementById('intro').addEventListener('click', function() {
      if (!this.classList.contains('closed')) {
        this.classList.add('closed');
      }
    });

    window.setTimeout(function() {
      document.getElementById('intro').classList.add('closed');
    }, 5000);

  }
  $('.bio').fitVids();
  $('.contact').fitVids();
  $('.project').fitVids();

});